import {ReactNode} from 'react'
import styled from '@emotion/styled'

import {Link} from 'src/components/Link'
import {typography} from 'src/styles'

type Ancestor = {permalink: string; name: string}
export type CreateLinkFn = (a: Ancestor) => ReactNode

interface Props {
  ancestors: Ancestor[]
  createLink?: CreateLinkFn
}

const defaultLinkFn: CreateLinkFn = (ancestor) => (
  <Link to={ancestor.permalink}>{ancestor.name}</Link>
)

const Container = styled.ul(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  color: theme.colors.grey5,

  a: {
    color: theme.colors.grey5,
    textDecorationColor: theme.colors.grey5,
  },

  'li:last-of-type > a': {
    color: theme.colors.link,
    textDecorationColor: theme.colors.link,
  },

  'li + li': {
    marginLeft: theme.space[1],
    ':before': {
      content: '"/"',
      marginRight: theme.space[1],
      ...typography.body,
    },
  },
}))

const Breadcrumbs = ({ancestors, createLink = defaultLinkFn}: Props) => (
  <Container>
    {ancestors.map((a) => (
      <li key={a.permalink}>{createLink(a)}</li>
    ))}
  </Container>
)

export default Breadcrumbs
