import {
  StaticMilestonePageFragment,
  Cms_Submilestone,
} from 'gatsby-graphql-types-generated'
import {graphql} from 'gatsby'

import Layout from 'src/components/Layout'
import SEO from 'src/components/SEO'
import JourneyStart from 'src/components/Journey/JourneyStart'
import Article from 'src/components/Journey/Article'
import {Grid, Box, Flex} from 'src/components/Box'
import Group from 'src/components/Journey/Group'
import {FlairProvider} from 'src/context/FlairContext'
import Breadcrumbs from 'src/components/Breadcrumbs'
import {
  GetToCollegeIcon,
  GetAJobIcon,
  SucceedAtCollegeIcon,
} from 'src/components/icons'
import {ActionLink} from 'src/components/Action'
import {useStudentJourney} from 'src/hooks/useStudentJourney'
import usePersonalizationFilter from 'src/hooks/usePersonalizationFilter'
import useTrackJourneyInteraction from 'src/hooks/useTrackJourneyInteraction'
import useProcessJourneyChildren, {
  JourneyChildType,
} from 'src/hooks/useProcessJourneyChildren'
import ResourcePageCard from 'src/components/Journey/ResourcePageCard'

const JourneyIcon = ({icon}: {icon?: string | null}) => {
  if (!icon) {
    return null
  }

  switch (icon) {
    case 'getCollege':
      return <GetToCollegeIcon />
    case 'job':
      return <GetAJobIcon />
    case 'successCollege':
      return <SucceedAtCollegeIcon />
    default:
      throw new Error('Unknown icon name.')
  }
}

export const query = graphql`
  fragment SubmilestoneCard on ContentfulSubmilestone {
    __typename
    id
    name
    description {
      description
    }
  }
  fragment StaticMilestonePage on ContentfulMilestone {
    __typename
    id
    contentful_id
    name
    description {
      description
    }
    permalink
    heroRemoteUrl
    heroFile {
      ...HeroImage
    }
    resourcePages {
      __typename
      id
      title
      blurb {
        blurb
      }
      permalink
    }
    journey {
      __typename
      id
      contentful_id
      name
      permalink
      icon
    }

    contentfulchildren {
      ...ArticleCard
      ...SubmilestoneCard
      ... on ContentfulSubmilestone {
        contentfulchildren {
          ...ArticleCard
        }
      }
    }
    ...JourneyMilestoneGrouping
  }
`

interface Props {
  milestone: StaticMilestonePageFragment
}

const MilestoneChild = ({child}: {child: JourneyChildType}) => {
  switch (child.__typename) {
    case 'ContentfulSubmilestone':
      return (
        <Box gridColumn="span 2">
          <Group
            title={child.name}
            description={child.description?.description}
          />
        </Box>
      )
    case 'ContentfulJourneyTile':
      return (
        child.tile && (
          <Box gridColumn={{_: 'span 2', md: 'span 1'}}>
            <Article
              heroUrl={
                child.tile.heroFile?.childImageSharp?.fluid ||
                child.tile.heroRemoteUrl
              }
              title={child.tile.title}
              description={child.tile.summary?.summary}
              link={child.permalink}
            />
          </Box>
        )
      )
    case 'ContentfulResourcePage':
      return (
        <Box gridColumn={{_: 'span 2', md: 'span 1'}}>
          <ResourcePageCard resourcePage={child} />
        </Box>
      )
    default:
      throw new Error('Unknown __typename for Milestone child.')
  }
}

const MilestonePageTemplate = ({milestone}: Props) => {
  useTrackJourneyInteraction(milestone.__typename, milestone.contentful_id)
  const journey = milestone.journey[0]
  const studentJourney = useStudentJourney(journey.contentful_id)
  const filteredMilestone = usePersonalizationFilter(
    milestone,
    studentJourney?.contentfulAnswerId
  )

  const journeyChildren = useProcessJourneyChildren(
    filteredMilestone,
    'ContentfulSubmilestone' as Cms_Submilestone['__typename']
  )

  return (
    <Layout>
      <SEO
        title={milestone.name}
        description={milestone.description?.description || undefined}
        permalink={milestone.permalink}
      />

      <FlairProvider seed={parseInt(milestone.id, 16)}>
        <Flex height="50px" alignItems="center" mt="5">
          <Breadcrumbs
            ancestors={[
              {
                name: journey.name,
                permalink: journey.permalink,
              },
            ]}
            createLink={(ancestor) => (
              <ActionLink to={ancestor.permalink}>
                <Flex
                  alignItems="center"
                  css={(theme) => ({
                    'svg + span': {marginLeft: theme.space[1]},
                  })}
                >
                  <JourneyIcon icon={journey.icon} />
                  <span>{ancestor.name}</span>
                </Flex>
              </ActionLink>
            )}
          />
        </Flex>

        <JourneyStart
          heroUrl={milestone.heroRemoteUrl}
          heroFile={milestone.heroFile}
          title={milestone.name}
          description={milestone.description?.description}
        />

        <Grid gridGap="5" gridTemplateColumns={{_: '1fr', md: '1fr 1fr'}}>
          {journeyChildren.map((child, index) => (
            <MilestoneChild key={index} child={child} />
          ))}
        </Grid>
      </FlairProvider>
    </Layout>
  )
}

export default MilestonePageTemplate
