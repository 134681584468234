import {MilestoneTemplatePageDataQuery} from 'gatsby-graphql-types-generated'
import {graphql, navigate} from 'gatsby'

import MilestonePageTemplate from 'src/components/MilestonePageTemplate'

interface Props {
  data: MilestoneTemplatePageDataQuery
}

export const query = graphql`
  query MilestoneTemplatePageData($id: String) {
    contentfulMilestone(id: {eq: $id}) {
      ...StaticMilestonePage
    }
  }
`

const MilestonePage = ({data}: Props) => {
  const milestone = data.contentfulMilestone

  if (!milestone) {
    navigate('404')
    return null
  }

  return <MilestonePageTemplate milestone={milestone} />
}

export default MilestonePage
